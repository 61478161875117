.App {
  text-align: center;
}

.Square {
  border-width: .15vmax;
  border-radius: 2px;
  border-style: solid;
  height: 100%;
  aspect-ratio: 1/1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  align-content: center;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  font-size: min(min(3cqw, 3cqh), 22px);
}

/* overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", display:"block" */

.floating-emoji {
  position: absolute;
  bottom: 0; /* Start from the bottom of the screen */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  animation: floatUp 3s ease-in-out infinite; /* Animation properties */
}

@keyframes floatUp {
  0% {
      transform: translate(-50%, -5vh); /* Start at the bottom */
      opacity: 0;
  }
  50% {
      transform: translate(-50%, -30vh); /* Move up and off the screen */
      opacity: 1;
  }
  100% {
      transform: translate(-50%, -5vh); /* Reset to starting position */
      opacity: 0;
  }
}

.MiniSquare {
  border-width: .1vmax;
  border-radius: 2px;
  border-style: solid;
  display: block;
  height: 100%;
  aspect-ratio: 1/1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  align-content: center;
  font-size:large;
}

.slide-in {
  transform: translateX(-100%); /* Start off-screen */
  opacity: 0; /* Start invisible */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Duration and easing */
}

.slide-in-active {
  transform: translateX(0); /* Slide onto the screen */
  opacity: 1; /* Fade in */
}

.Board {
  width: min(100%, 90vh);
  max-width: 98%;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 5px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.spinner {
  animation: spin 2s linear infinite; /* Apply the spin animation */
}

@keyframes spin {
  0% {
      transform: rotate(360deg); /* Start at 0 degrees */
  }
  100% {
      transform: rotate(0deg); /* Rotate to 360 degrees */
  }
}
